import { useTranslation } from "react-i18next";
import DefaultError from "../../../DefaultError";
import { Typography } from "@mui/material";

interface NoCameraFoundErrorProps {
  onComeBackLater: Function;
  onTryAgain: Function;
}

export default function NoCameraFoundError({
  onComeBackLater,
  onTryAgain,
}: NoCameraFoundErrorProps) {
  const { t } = useTranslation();

  return (
    <DefaultError onComeBackLater={onComeBackLater} onTryAgain={onTryAgain}>
      <Typography variant="h2">{t("NoCameraFoundError.title")}</Typography>
      <Typography pb={2}>{t("NoCameraFoundError.content")}</Typography>
    </DefaultError>
  );
}
