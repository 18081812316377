import { useState, useEffect } from "react";
import { MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styleInputDefault } from "../../theme";
import ChevronDownIcon from "../../theme/icons/ChevronDownIcon";
import {
  clampNumber,
  convertLength,
  normaliseStringToNumberWithPeriod,
} from "../../utils";

interface HeightInputProps {
  data?: {
    unit: string;
    value: string;
  };
  onChange?: Function;
}

export const heightUnits = [
  { id: "CM", label: "CM" },
  { id: "M", label: "M" },
];

const maxCM = 300;
const minCM = 50;

const maxM = 3;
const minM = 0.5;

export default function HeightInput({ data, onChange }: HeightInputProps) {
  const [value, setValue] = useState((data && data.value) || "");
  const [unit, setUnit] = useState((data && data.unit) || "");

  const { t } = useTranslation();

  const handleOnInput = (
    internalValue: string,
    inputType: "value" | "unit"
  ) => {
    switch (inputType) {
      case "value":
        setValue(
          validateHeight(normaliseStringToNumberWithPeriod(internalValue), unit)
        );
        break;

      case "unit":
        if (internalValue === "M" && +value > maxM) {
          setValue(
            clampNumber(
              convertLength("CM", "M", Number(value)),
              minM,
              maxM
            ).toString()
          );
        } else if (internalValue === "CM" && value && unit) {
          setValue(
            clampNumber(
              convertLength("M", "CM", Number(value)),
              minCM,
              maxCM
            ).toString()
          );
        }
        setUnit(internalValue);
        break;
    }
  };

  const validateHeight = (internalValue: string, internalUnit: string = "") => {
    if (internalUnit === "M" && Number(internalValue) > maxM) {
      return clampNumber(Number(internalValue), minM, maxM).toString();
    } else if (Number(internalValue) > maxCM) {
      return clampNumber(Number(internalValue), minCM, maxCM).toString();
    }
    return internalValue;
  };

  const handleOnBlur = (internalValue: string) => {
    if (unit === "M" && internalValue) {
      setValue(clampNumber(Number(internalValue), minM, maxM).toString());
    } else if (internalValue) {
      setValue(clampNumber(Number(internalValue), minCM, maxCM).toString());
    }
  };

  useEffect(() => {
    setValue(data?.value || "");
    setUnit(data?.unit || "");
  }, [data?.value, data?.unit]);

  useEffect(() => {
    onChange && onChange({ value: value, unit: unit });
  }, [unit, value]);

  return (
    <>
      <Stack direction="row" spacing={1} aria-label={t("HeightInput.label")}>
        <Stack sx={{ width: "50%" }}>
          <TextField
            onChange={(event) => handleOnInput(event.target.value, "value")}
            onBlur={(event) => handleOnBlur(event.target.value)}
            value={value}
            inputProps={{
              autoComplete: "off",
              inputMode: "numeric",
              "aria-label": t("HeightInput.options.HEIGHT.inputLabel"),
            }}
            size="small"
            placeholder={t("HeightInput.options.HEIGHT.inputPlaceholder")}
            sx={{
              input: {
                ...styleInputDefault,
              },
            }}
          />
        </Stack>
        <Stack sx={{ width: "50%" }}>
          <Select
            fullWidth
            value={unit}
            displayEmpty
            sx={{
              height: "50px",
            }}
            IconComponent={ChevronDownIcon}
            inputProps={{
              "aria-label": t("HeightInput.options.UNIT.inputLabel"),
              placeholder: t("HeightInput.options.UNIT.inputLabel"),
            }}
            MenuProps={{
              "aria-label": t("HeightInput.options.UNIT.inputLabel"),
            }}
            renderValue={
              unit !== ""
                ? undefined
                : () => (
                    <Typography
                      sx={{
                        color: (theme) => theme.palette.neutral[700],
                        opacity: ".6",
                      }}
                    >
                      {t("common.selectPlaceholder")}
                    </Typography>
                  )
            }
            onChange={(event) => {
              handleOnInput(event.target.value, "unit");
            }}
          >
            {heightUnits.map((el) => (
              <MenuItem aria-label={`${el.label}`} key={el.id} value={el.label}>
                {el.label}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </Stack>
    </>
  );
}
