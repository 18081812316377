import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import WeightInput from "../WeightInput";

interface Props {
  onChange: Function;
  data?: { value: string };
}

export default function QuestionTypeWeight(props: Props) {
  const { t } = useTranslation();

  function onChange(value: string) {
    props.onChange({
      data: { value: value },
      isValid: Number(value) > 0 && Number(value) < 500,
    });
  }

  return (
    <>
      <Stack spacing={2} justifyContent="space-between">
        <Stack spacing={2}>
          <Stack spacing={4}>
            <Typography variant="h3">
              {t("QuestionTypeWeight.title")}
            </Typography>
            <WeightInput value={props?.data?.value} onChange={onChange} />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
