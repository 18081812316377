import {
  Box,
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import InstructionsImage from "./instructions-cover.png";
import CircledBackButton from "../../../../../CircledBackButton";
import { trackEvent } from "../../../../../../services/analytics-adapter";
import IconLoader from "../../../../../IconLoader";
import { convertPxToRem } from "../../../../../../utils";

interface IntercareProps {
  onComeBackLater: () => void;
  onConfirm?: () => void;
  onBackButtonClick?: () => void;
  shouldHideCTAs?: boolean;
}

interface ListWrapperProps {
  headingText: string;
  instructions: string[];
  icon: any;
  listItemTextColor: string;
}

function ListWrapper({
  instructions,
  headingText,
  icon,
  listItemTextColor,
}: ListWrapperProps) {
  return (
    <>
      <Typography variant="h2">{headingText}</Typography>
      <Stack
        component="ul"
        role="list"
        aria-label={`${headingText} instruction list`}
      >
        {instructions.map((listItemText) => (
          <ListItem
            role="listitem"
            sx={{ padding: "2px 0" }}
            key={listItemText}
          >
            <ListItemIcon sx={{ display: "contents" }}>{icon}</ListItemIcon>
            <ListItemText sx={{ pl: 2 }}>
              <Typography color={listItemTextColor}>{listItemText}</Typography>
            </ListItemText>
          </ListItem>
        ))}
      </Stack>
    </>
  );
}

export default function Intercare({
  onConfirm,
  onComeBackLater,
  onBackButtonClick,
  shouldHideCTAs = false,
}: IntercareProps) {
  const { t } = useTranslation();

  function onComeBackLaterButtonClick() {
    trackEvent({
      event: "action.healthCheckFaceScanSkipped",
      source: "health check",
    });
    onComeBackLater();
  }

  return (
    <Stack
      spacing={4}
      p={shouldHideCTAs ? 0 : 2}
      sx={{
        height: "100%",
        overflowX: "hidden",
      }}
    >
      <Stack>
        {shouldHideCTAs === false && (
          <CircledBackButton showLabel={true} onClick={onBackButtonClick} />
        )}
        <Box
          src={`/assets/MedicalAssistancePartners/Intercare/logo-color.svg`}
          height={40}
          my={2}
          alignSelf="flex-start"
          component="img"
          sx={{
            zIndex: 2,
          }}
          alt={t("CVPFlow.screens.HealthRecord.animationAlternateText")}
        />
      </Stack>

      <Stack spacing={1}>
        {shouldHideCTAs === false && (
          <Typography variant="h2" fontWeight={200}>
            {t("BinahScanFlow.intercare.instructions.title")}
          </Typography>
        )}
        <Typography variant="body2" color="neutral.500">
          {t("BinahScanFlow.instructions.subtitle")}
        </Typography>
      </Stack>
      <Box
        m={2}
        sx={{
          backgroundImage: `url(${InstructionsImage})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          minHeight: "240px",
        }}
      />
      <Stack spacing={3} sx={{ flex: 2 }}>
        <Box>
          <ListWrapper
            headingText={t("BinahScanFlow.instructions.set1Title")}
            listItemTextColor="neutral.main"
            icon={
              <Box
                bgcolor="intercare.dark"
                borderRadius="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                height={22}
                width={22}
              >
                <IconLoader
                  color="info"
                  icon="CheckmarkIcon"
                  sx={{ fontSize: convertPxToRem(15) }}
                />
              </Box>
            }
            instructions={
              t("BinahScanFlow.instructions.set1", {
                returnObjects: true,
              }) || []
            }
          />
        </Box>
        <Box>
          <ListWrapper
            headingText={t("BinahScanFlow.instructions.set2Title")}
            listItemTextColor="neutral.500"
            icon={
              <Box
                bgcolor="neutral.700"
                borderRadius="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                height={22}
                width={22}
              >
                <IconLoader
                  color="info"
                  icon="CrossIcon"
                  sx={{ fontSize: convertPxToRem(15) }}
                />
              </Box>
            }
            instructions={
              t("BinahScanFlow.instructions.set2", {
                returnObjects: true,
              }) || []
            }
          />
        </Box>
      </Stack>

      <Stack direction={"row"} spacing={1}>
        <Button
          variant="outlined"
          color="intercare"
          fullWidth
          onClick={onComeBackLaterButtonClick}
        >
          {t("common.comeBackButton")}
        </Button>
        {shouldHideCTAs === false && (
          <Button color="intercare" fullWidth onClick={onConfirm}>
            {t("common.continueButton")}
          </Button>
        )}
      </Stack>
    </Stack>
  );
}
