import { AriaRole, PropsWithChildren } from "react";
import { Box } from "@mui/material";
import { panelStyle } from "../../theme";

interface Props {
  accentColour?: string;
  role?: AriaRole;
  padding?: number;
  overflow?: string;
  "aria-label"?: string;
}

export default function Panel(props: PropsWithChildren<Props>) {
  const paddingStyle =
    typeof props.padding !== "undefined" ? { p: props.padding } : null;
  const overflowStyle =
    typeof props.overflow !== "undefined" ? { overflow: props.overflow } : null;

  return (
    <Box
      role={props.role}
      aria-label={props["aria-label"]}
      sx={{
        ...panelStyle,
        ...paddingStyle,
        ...overflowStyle,
      }}
    >
      {props.children}

      {props.accentColour && (
        <Box
          bgcolor={props.accentColour}
          sx={{
            height: "100%",
            left: 0,
            position: "absolute",
            top: 0,
            width: 4,
          }}
        ></Box>
      )}
    </Box>
  );
}
