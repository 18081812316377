import { Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { styleInputDefault } from "../../theme";
import { normaliseStringToNumberWithPeriod } from "../../utils";

interface Props {
  value?: string;
  onChange?: Function;
}

const MAXIMUM_WEIGHT_IN_KG = 500;

export default function WeightInput(props: Props) {
  const { t } = useTranslation();
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(props.value || "");
  }, [props.value]);

  useEffect(() => {
    props.onChange && props.onChange(value);
  }, [value]);

  const handleOnInput = (internalValue: string) => {
    setValue(validateWeight(normaliseStringToNumberWithPeriod(internalValue)));
  };

  const handleOnBlur = (internalValue: string) => {
    if (internalValue && Number(internalValue) === 0) {
      setValue("1");
      return;
    }
    setValue(internalValue);
  };

  const validateWeight = (internalValue: string) => {
    if (Number(internalValue) > MAXIMUM_WEIGHT_IN_KG) {
      return String(MAXIMUM_WEIGHT_IN_KG);
    }
    return internalValue;
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        aria-label={t("WeightInput.label")}
        spacing={2}
      >
        <Stack flexGrow={2}>
          <TextField
            onInput={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleOnInput(event.target.value)
            }
            onBlur={(event) => handleOnBlur(event.target.value)}
            value={value}
            inputProps={{
              autoComplete: "off",
              inputMode: "numeric",
              "aria-label": t("WeightInput.options.weight.inputLabel"),
            }}
            size="small"
            placeholder={t("WeightInput.options.weight.inputPlaceholder")}
            sx={{
              input: {
                ...styleInputDefault,
              },
            }}
          />
        </Stack>
        <Stack>
          <Typography variant="h2">{t("Weight.unit.kg")}</Typography>
        </Stack>
      </Stack>
    </>
  );
}
