import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";
import { convertBooleanLikeStringToBoolean } from "../../utils";
import DefaultToggleButtonGroup from "../DefaultToggleButtonGroup";

interface Props {
  data?: string;
  onChange?: Function;
}

const getValueFromProps = (props: Props) => {
  return convertBooleanLikeStringToBoolean(props?.data);
};

export default function QuestionTypeSmokingStatus(props: Props) {
  const { t } = useTranslation();
  const [internalValue, setInternalValue] = useState<boolean | null>(
    getValueFromProps(props)
  );

  const options = [
    {
      name: t("common.yes"),
      value: true,
    },
    {
      name: t("common.no"),
      value: false,
    },
  ];

  useEffect(() => {
    setInternalValue(getValueFromProps(props));
  }, [props?.data]);

  useEffect(() => {
    props.onChange &&
      props.onChange({
        data: {
          value: internalValue === true ? "Yes" : "No",
        },
        isValid: internalValue !== null,
      });
  }, [internalValue]);

  return (
    <Stack spacing={2} justifyContent="space-between">
      <Stack spacing={2}>
        <Typography variant="h3">
          {t("QuestionTypeSmokingStatus.title")}
        </Typography>
        <DefaultToggleButtonGroup
          options={options}
          value={internalValue}
          onChange={(_event, value) => setInternalValue(value)}
          fullWidth
          exclusive
          color="neutral"
        />
      </Stack>
    </Stack>
  );
}
