import { Stack } from "@mui/material";
import FaceScanComplete from ".";

export default function FaceScanCompleteExamples() {
  const scanResults = {
    bloodPressure: {
      value: {
        systolic: 166,
        diastolic: 56,
      },
    },
    hemoglobinA1c: { value: 5.7 },
    heartRate: { value: 88 },
  };

  return (
    <Stack sx={{ height: "100vh" }}>
      <FaceScanComplete
        scanResults={scanResults}
        onDone={() => console.log("onDone button clicked")}
      />
    </Stack>
  );
}
