import { useTranslation } from "react-i18next";
import DefaultError from "../../../DefaultError";
import { Stack, Typography } from "@mui/material";

interface DeviceRequirementsErrorProps {
  onComeBackLater: Function;
  onTryAgain: Function;
}

export default function DeviceRequirementsError({
  onComeBackLater,
  onTryAgain,
}: DeviceRequirementsErrorProps) {
  const { t } = useTranslation();

  return (
    <DefaultError onComeBackLater={onComeBackLater} onTryAgain={onTryAgain}>
      <Typography variant="h2">{t("DeviceRequirementsError.title")}</Typography>
      <Typography>{t("DeviceRequirementsError.subtitle")}</Typography>
      <Stack pl={3} py={2}>
        <ul>
          {(
            t<any, any, string[]>("DeviceRequirementsError.content", {
              returnObjects: true,
            }) as []
          ).map((text: string, index: number) => (
            <li key={String(text + index)}>
              <Typography>{text}</Typography>
            </li>
          ))}
        </ul>
      </Stack>
    </DefaultError>
  );
}
