import { PropsWithChildren } from "react";
import DefaultDialog, { DefaultDialogProps } from "../DefaultDialog";
import { slideUpModalStyle } from "../../theme";

export default function SlideUpDialog({
  children,
  isOpen,
  onClose,
  title,
  label,
  actionButtons,
  isFullscreen = false,
  closeButton = true,
  fullWidth = true,
  isConstrainedToMaxWidthContainer = false,
}: PropsWithChildren<DefaultDialogProps>) {
  return (
    <DefaultDialog
      isOpen={isOpen}
      onClose={onClose}
      animate={true}
      closeButton={closeButton}
      paperStyleProps={{
        ...slideUpModalStyle,
      }}
      title={title}
      label={label}
      actionButtons={actionButtons}
      isFullscreen={isFullscreen}
      direction="up"
      fullWidth={fullWidth}
      isConstrainedToMaxWidthContainer={isConstrainedToMaxWidthContainer}
    >
      {children}
    </DefaultDialog>
  );
}
