import Default from "./components/Default";
import Intercare from "./components/Intercare";
import { HealthCheckProvider } from "../../../../services/core-api-adapter";

interface ScanInstructionsProps {
  onConfirm?: () => void;
  onComeBackLater: () => void;
  onBackButtonClick?: () => void;
  provider?: string;
  shouldHideCTAs?: boolean;
}

export default function ScanInstructions({
  onConfirm,
  onComeBackLater,
  onBackButtonClick,
  provider,
  shouldHideCTAs = false,
}: ScanInstructionsProps) {
  return (
    <>
      {provider === HealthCheckProvider.INTERCARE && (
        <Intercare
          shouldHideCTAs={shouldHideCTAs}
          onConfirm={onConfirm}
          onComeBackLater={onComeBackLater}
          onBackButtonClick={onBackButtonClick}
        />
      )}
      {provider !== HealthCheckProvider.INTERCARE && (
        <Default
          shouldHideCTAs={shouldHideCTAs}
          onConfirm={onConfirm}
          onComeBackLater={onComeBackLater}
          onBackButtonClick={onBackButtonClick}
        />
      )}
    </>
  );
}
