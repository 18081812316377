import { Stack, Typography } from "@mui/material";
import ScanInstructions from ".";
import { HealthCheckProvider } from "../../../../services/core-api-adapter";

export default function ScanInstructionsExamples() {
  return (
    <Stack>
      <Typography textAlign="center">Default</Typography>
      <ScanInstructions
        onConfirm={() => {}}
        onComeBackLater={() => {}}
        onBackButtonClick={() => {}}
      />
      <Typography textAlign="center">Intercare</Typography>
      <ScanInstructions
        provider={HealthCheckProvider.INTERCARE}
        onConfirm={() => {}}
        onComeBackLater={() => {}}
        onBackButtonClick={() => {}}
      />
    </Stack>
  );
}
