import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultToggleButtonGroup from "../DefaultToggleButtonGroup";

interface Props {
  data?: string;
  onChange?: Function;
}

const getValueFromDataProp = (value: string | undefined) => {
  if (value === "M" || value?.toUpperCase() === "MALE") {
    return "Male";
  } else if (value === "F" || value?.toUpperCase() === "FEMALE") {
    return "Female";
  }

  return null;
};

export default function QuestionTypeSexAtBirth(props: Props) {
  const { t } = useTranslation();

  const [internalValue, setInternalValue] = useState<string | null>(null);

  const options = [
    {
      name: t("common.male"),
      value: "Male",
    },
    {
      name: t("common.female"),
      value: "Female",
    },
  ];

  useEffect(() => {
    setInternalValue(getValueFromDataProp(props?.data));
  }, [props?.data]);

  useEffect(() => {
    props.onChange &&
      props.onChange({
        data: { value: internalValue },
        isValid: internalValue !== null,
      });
  }, [internalValue]);

  return (
    <Stack spacing={2} justifyContent="space-between">
      <Stack spacing={4}>
        <Typography variant="h3">
          {t("QuestionTypeSexAtBirth.title")}
        </Typography>
        <DefaultToggleButtonGroup
          options={options}
          value={internalValue}
          onChange={(_event, value) => setInternalValue(value)}
          fullWidth
          exclusive
          color="neutral"
        />
      </Stack>
    </Stack>
  );
}
