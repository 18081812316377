import { HealthMonitorCodes, VitalSigns } from "@binah/web-sdk";
import { Box, Button, Fade, Stack, Theme, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { MemoizedScanner } from "../Scanner";
import { useTimer } from "../../../../services/health-scan/hooks";
import CircularProgress, {
  CircularProgressProps,
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import GenericError from "../GenericError";
import DeviceRequirementsError from "../DeviceRequirementsError";
import PermissionIssuesError from "../PermissionIssuesError";
import NoCameraFoundError from "../NoCameraFoundError";
import {
  HealthCheckQuestionnaireData,
  submitFaceScanMeasurements,
} from "../../../../services/core-api-adapter";
import { InfoData } from "../../../../services/health-scan/types";
import IconLoader from "../../../IconLoader";
import FaceScanComplete from "../FaceScanComplete";
import { trackEvent } from "../../../../services/analytics-adapter";
import ScanInstructions from "../ScanInstructions";
import SlideUpDialog from "../../../SlideUpDialog";

enum ErrorMap {
  GENERIC = "GENERIC",
  DEVICE = "DEVICE",
  PERMISSIONS_ISSUE = "PERMISSIONS_ISSUE",
  NO_CAMERA_FOUND = "NO_CAMERA_FOUND",
  BATTERY_TOO_LOW = "BATTERY_TOO_LOW",
}

const stateNames = {
  READY_TO_SCAN: "READY_TO_SCAN",
  INITIALISE_SCANNER: "INITIALISE_SCANNER",
  INITIALISE_SCANNER_FAILED: "INITIALISE_SCANNER_FAILED",
  INITIALISE_SCANNER_SUCCEEDED: "INITIALISE_SCANNER_SUCCEEDED",
  SCAN_IN_PROGRESS: "SCAN_IN_PROGRESS",
  SCAN_COMPLETED: "SCAN_COMPLETED",
  SCAN_RETRY: "SCAN_RETRY",
  EXIT: "EXIT",
};

const DEFAULT_MEASUREMENT_DURATION =
  Number(import.meta.env.VITE_APP_BINAH_AI_SDK_SCAN_MEASUREMENT_DURATION) ||
  120;

interface FaceScanError {
  showError: boolean;
  errorType: string;
  errorCode: HealthMonitorCodes;
}

interface FaceScanErrorProps {
  error: FaceScanError;
  onComeBackLater: Function;
  onTryAgain: Function;
}

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number; showLabel: boolean }
) {
  const PROGRESS_SIZE = "23rem";

  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        justifyContent: "center",
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color: "white",
        }}
        size={PROGRESS_SIZE}
        thickness={1}
        value={100}
      />

      <CircularProgress
        variant={props.variant || "determinate"}
        disableShrink
        sx={{
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        value={props.value}
        color={props.color}
        size={PROGRESS_SIZE}
        thickness={1.2}
      />
      {props.showLabel && (
        <Box
          sx={{
            top: 0,
            left: "50%",
            transform: "translate(-50%, -40%)",
            borderRadius: 5,
            width: (theme) => theme.spacing(8),
            background: "white",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            py: 1,
            position: "absolute",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: () =>
                props.color === "success" ? "success.main" : "primary.main",
            }}
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      )}
    </Box>
  );
}

function FaceScanError({
  error,
  onComeBackLater,
  onTryAgain,
}: FaceScanErrorProps) {
  switch (error.errorType) {
    case ErrorMap.GENERIC:
      return (
        <GenericError
          onComeBackLater={onComeBackLater}
          onTryAgain={onTryAgain}
          errorCode={error.errorCode}
        />
      );
    case ErrorMap.PERMISSIONS_ISSUE:
      return (
        <PermissionIssuesError
          onComeBackLater={onComeBackLater}
          onTryAgain={onTryAgain}
        />
      );
    case ErrorMap.NO_CAMERA_FOUND:
      return (
        <NoCameraFoundError
          onComeBackLater={onComeBackLater}
          onTryAgain={onTryAgain}
        />
      );
    case ErrorMap.DEVICE:
      return (
        <DeviceRequirementsError
          onComeBackLater={onComeBackLater}
          onTryAgain={onTryAgain}
        />
      );
  }

  return (
    <GenericError
      onComeBackLater={onComeBackLater}
      onTryAgain={onTryAgain}
      errorCode={error.errorCode}
    />
  );
}

interface MeasureProps {
  healthCheckQuestionnaireData: HealthCheckQuestionnaireData | null;
  onCompletedScan: Function;
  onScanRetry: Function;
  onComeBackLaterButtonClick: Function;
}

export default function Measure({
  onCompletedScan,
  onScanRetry,
  healthCheckQuestionnaireData,
  onComeBackLaterButtonClick,
}: MeasureProps) {
  const { t } = useTranslation();
  const [measurementProgress, setMeasurementProgress] = useState(0);

  const navigate = useNavigate();
  const measurementDuration = DEFAULT_MEASUREMENT_DURATION;
  const [error, setError] = useState<FaceScanError | null>(null);
  const [currentState, setCurrentState] = useState(stateNames.READY_TO_SCAN);
  const [scanResults, setScanResults] = useState<VitalSigns | null>(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [startMeasuringState, setStartMeasuringState] = useState(false);
  const [isScanReady, setIsScanReady] = useState(false);
  const [isScanInstructionsOpen, setIsScanInstructionsOpen] = useState(false);
  const seconds = useTimer(startMeasuringState, measurementDuration);

  function getPercentage() {
    const computedPercentage = seconds / DEFAULT_MEASUREMENT_DURATION;
    const percentage = Math.floor(computedPercentage * 100);
    return percentage;
  }

  function onTryAgain() {
    onScanRetry(error?.errorCode);
  }

  function onComeBackLater() {
    onComeBackLaterButtonClick(error?.errorCode);
  }

  const onError = useCallback((error: any) => {
    if (error.code && error.code >= 0) {
      console.error(error);
      const errorCode = error.code;

      if (
        errorCode ===
        HealthMonitorCodes.DEVICE_CODE_MINIMUM_BROWSER_VERSION_ERROR
      ) {
        trackEvent({
          event: "action.healthCheckIncompatibleDevice",
          source: "health check",
        });
      } else {
        trackEvent({
          event: "action.healthCheckFaceScanErrorEncountered",
          source: "health check",
          errorCode: errorCode,
        });
      }

      switch (errorCode) {
        case HealthMonitorCodes.DEVICE_CODE_MINIMUM_BROWSER_VERSION_ERROR:
          setError({
            showError: true,
            errorType: ErrorMap.DEVICE,
            errorCode: errorCode,
          });
          break;
        case HealthMonitorCodes.CAMERA_CODE_CAMERA_MISSING_PERMISSIONS_ERROR:
          setError({
            showError: true,
            errorType: ErrorMap.PERMISSIONS_ISSUE,
            errorCode: errorCode,
          });
          break;
        case HealthMonitorCodes.CAMERA_CODE_NO_CAMERA_ERROR:
          setError({
            showError: true,
            errorType: ErrorMap.NO_CAMERA_FOUND,
            errorCode: errorCode,
          });
          break;
        default:
          setError({
            showError: true,
            errorType: ErrorMap.GENERIC,
            errorCode: errorCode,
          });
          break;
      }
    }
  }, []);

  function startMeasuring() {
    setCurrentState(stateNames.INITIALISE_SCANNER);
    setStartMeasuringState(true);

    if (currentState !== stateNames.INITIALISE_SCANNER_FAILED) {
      setCurrentState(stateNames.INITIALISE_SCANNER_SUCCEEDED);
      setCurrentState(stateNames.SCAN_IN_PROGRESS);

      trackEvent({
        event: "action.healthCheckFaceScanStarted",
        source: "health check",
      });
    }
  }

  function stopMeasuring() {
    setCurrentState(stateNames.READY_TO_SCAN);
    setStartMeasuringState(false);
    setMeasurementProgress(0);
    navigate("/home");
  }

  function onScanReady() {
    setIsScanReady(true);
  }

  function onScanComplete(vitalSigns: any) {
    setCurrentState(stateNames.SCAN_COMPLETED);
    setScanResults(vitalSigns);
    submitFaceScanMeasurements({
      vitalSigns: vitalSigns,
      healthCheckQuestionnaireData: healthCheckQuestionnaireData,
    });

    trackEvent({
      event: "action.healthCheckCompleted",
      source: "Health check",
    });
  }

  const onAlert = useCallback(
    (warningData: InfoData) => {
      if (warningData.type <= 0 && !error?.showError) {
        setAlertMessage("");
      }

      if (warningData.message) {
        setAlertMessage(warningData.message);
      }
    },
    [error]
  );

  function onInformationIconButtonClick() {
    setIsScanInstructionsOpen(true);
  }

  useEffect(() => {
    if (getPercentage() <= 100) {
      setMeasurementProgress(getPercentage());
    }
  }, [seconds]);

  return (
    <>
      {error && error?.showError === true ? (
        <FaceScanError
          error={error}
          onComeBackLater={onComeBackLater}
          onTryAgain={onTryAgain}
        />
      ) : (
        <>
          <>
            {(currentState === stateNames.READY_TO_SCAN ||
              currentState === stateNames.SCAN_IN_PROGRESS ||
              currentState === stateNames.SCAN_RETRY) &&
              currentState !== stateNames.SCAN_COMPLETED && (
                <Stack
                  sx={{
                    position: "absolute",
                    zIndex: 3,
                    top: "20%",
                    left: "50%",
                    transform: "translate(-50%, 0)",
                  }}
                  spacing={2}
                >
                  <CircularProgressWithLabel
                    value={measurementProgress}
                    showLabel={currentState === stateNames.SCAN_IN_PROGRESS}
                    variant="determinate"
                    color={measurementProgress === 100 ? "success" : "primary"}
                  />
                  <Stack color="neutral.contrastText">
                    {currentState === stateNames.SCAN_RETRY ? (
                      <Typography
                        variant="h2"
                        textAlign="center"
                        color="inherit"
                      >
                        {t("common.genericErrorRetryMessage")}
                      </Typography>
                    ) : (
                      <>
                        <Typography
                          variant="h2"
                          textAlign="center"
                          color="inherit"
                        >
                          {alertMessage.match("Warning")
                            ? t(
                                "BinahScanFlow.measurenow.state.SCAN_IN_PROGRESS.title"
                              )
                            : alertMessage}
                        </Typography>
                      </>
                    )}
                  </Stack>
                </Stack>
              )}
            {currentState !== stateNames.SCAN_COMPLETED && (
              <Stack
                sx={{
                  height: "100%",
                  position: "relative",
                }}
              >
                <MemoizedScanner
                  onError={onError}
                  onAlert={onAlert}
                  onStartMeasuring={startMeasuringState}
                  measurementDuration={measurementDuration}
                  onScanReady={onScanReady}
                  onScanComplete={onScanComplete}
                  healthCheckQuestionnaireData={healthCheckQuestionnaireData}
                  onBackButton={onComeBackLaterButtonClick}
                />
                <Stack
                  sx={{
                    position: "absolute",
                    top: (theme) => theme.spacing(2),
                    right: (theme) => theme.spacing(2),
                  }}
                >
                  <IconLoader
                    icon="InformationSymbolFilledCircleIcon"
                    onClick={onInformationIconButtonClick}
                    sx={{
                      color: "primary.700",
                      fontSize: 30,
                    }}
                  />
                </Stack>

                <Stack
                  bgcolor="background.paper"
                  sx={{
                    borderRadius: (theme: Theme) =>
                      `${theme.spacing(1.5)} ${theme.spacing(1.5)} 0 0`,
                    bottom: "0",
                    width: "100%",
                    position: "absolute",
                    px: 2,
                    py: 1.5,
                  }}
                >
                  <Stack>
                    {(currentState === stateNames.SCAN_IN_PROGRESS ||
                      currentState === stateNames.SCAN_RETRY) && (
                      <Stack direction="row" spacing={1}>
                        <Button
                          variant="outlined"
                          fullWidth
                          onClick={stopMeasuring}
                        >
                          {t("common.comeBackButton")}
                        </Button>
                        <Button
                          disabled={
                            currentState === stateNames.SCAN_IN_PROGRESS
                          }
                          fullWidth
                          onClick={startMeasuring}
                        >
                          {t("common.tryAgain")}
                        </Button>
                      </Stack>
                    )}

                    {currentState !== stateNames.SCAN_IN_PROGRESS &&
                      currentState === stateNames.READY_TO_SCAN && (
                        <Button
                          fullWidth
                          onClick={startMeasuring}
                          disabled={!isScanReady}
                        >
                          {t("BinahScanFlow.measurenow.measureButton")}
                        </Button>
                      )}
                  </Stack>
                </Stack>
              </Stack>
            )}
          </>
          {currentState !== stateNames.SCAN_IN_PROGRESS &&
            currentState === stateNames.SCAN_COMPLETED && (
              <Fade in={true}>
                <Box
                  sx={{
                    height: "100%",
                  }}
                >
                  <FaceScanComplete
                    scanResults={scanResults}
                    onDone={onCompletedScan}
                  />
                </Box>
              </Fade>
            )}
        </>
      )}
      <SlideUpDialog
        isOpen={isScanInstructionsOpen}
        onClose={() => setIsScanInstructionsOpen(false)}
      >
        <ScanInstructions
          shouldHideCTAs={true}
          onComeBackLater={onComeBackLater}
        />
      </SlideUpDialog>
    </>
  );
}
